<template>
  <div class="login">
    <div class="row justify-content-center" v-if="!reset">
      <div class="col-12 col-md-8">
        <h1 class="page-title">Login</h1>
        <b-form autocomplete="off" @submit.stop.prevent="onFormLoginSubmit">
          <div class="row">
            <div class="col-12">
              <b-form-group label="Email">
                <b-form-input v-model="form.email" type="email" trim required />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b-form-group label="Password">
                <b-form-input v-model="form.password" type="password" trim required />
              </b-form-group>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-12 col-md-6">
              <div class="form-actions">
                <b-btn variant="outline-primary" @click.stop.prevent="onClickCreateAccount">
                  CREATe An ACCOUNT
                </b-btn>
                <b-overlay
                  :show="busy"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-btn variant="primary" type="submit" :disabled="busy">
                    Login
                  </b-btn>
                </b-overlay>
              </div>
            </div>
          </div>
          <a href="javascript:void(0)" @click="reset = true">Forgot your password?</a>
        </b-form>
      </div>
    </div>
    <div class="row justify-content-center" v-if="reset">
      <div class="col-12 col-md-8">
        <h1 class="page-title">Reset password</h1>
        <b-form autocomplete="off" @submit.stop.prevent="onFormResetSubmit">
          <div class="row">
            <div class="col-12">
              <b-form-group label="Email">
                <b-form-input v-model="form.email" type="email" trim required></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-12 col-md-6">
              <div class="form-actions">
                <b-overlay
                  :show="busy"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-btn variant="primary" type="submit" :disabled="busy">
                    Reset password
                  </b-btn>
                </b-overlay>
              </div>
            </div>
          </div>
          <a href="javascript:void(0)" @click="reset = false">Login</a>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "@/mixin/toast"
import AuthService from '@/services/auth.service'
export default {
  name: 'Login',
  metaInfo: {
    title: 'Login',
  },
  mixins: [ Toast ],
  components: {
  },
  data() {
    return {
      reset: false,
      busy: false,
      form: {
        email: '',
        password: '',
      }
    }
  },
  computed: {
    cart(){
      return this.$store.getters.cart;
    }
  },
  methods: {
    async onFormLoginSubmit() {
      this.busy = true;
      this.$store.dispatch('AuthModule/login', this.form).then((result) => {
        this.busy = false;
        if(result.status == 200){
          this.$router.push(`/`);
        } else {
          this.showToast('danger', 'Error', result.data.errors[0]);
        }
      }, (error) => {
        this.busy = false;
        console.error(error)
        error.data.errors[0] && this.showToast('danger', 'Error', error.data.errors[0]);
      })
    },
    async onFormResetSubmit() {
      this.busy = true;
      AuthService.reset({email: this.form.email}).then((result) => {
        this.busy = false;
        if(result.data.status){
          this.form.email = '';
          this.showToast('success', 'Success', 'Verify your email');
        } else {
          this.showToast('danger', 'Error', result.data.errors[0]);
        }
      }, (error) => {
        this.busy = false;
        this.showToast('danger', 'Error', error);
      })
    },
    onClickCreateAccount() {
      this.$router.push(`/register`);
    }
  }
}
</script>

<style lang="scss">
.login {
  .page-title {
    margin: 6rem 0 3rem;
    font-size: 47px;
    font-weight: 700;
  }

  h3 {
    font-size: 29px;
    font-weight: 700;
  }

  .login-cta {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .form-group {
    margin: 0.7rem 0;

    legend {
      text-transform: uppercase;
      color: var(--whole-form-legend-color);;
      font-size: 14px;
      letter-spacing: 0.05em;
    }

    .form-control {
      border-color: var(--whole-text);
      background: var(--whole-black-form-bg);;
      font-size: 18px;
      color: var(--whole-text);
    }
  }

  .form-actions {
    margin: 2rem 0;
    text-align: right;
    // display: flex;
    // justify-content: space-between;

    .btn {
      border-radius: 0;
      font-weight: 500;
      font-size: 18px;
      text-transform: uppercase;
      &:last-child {
        margin-left: 1rem;
      }
    }
  }
}
</style>
